import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import universal from 'react-universal-component';
import cx from 'classnames';

import { Content } from '@motorway/motorway-storybook-cra';

import { ConfigContext } from 'Context/config';

import { GA_TAGS } from 'Utilities/analytics';

import StickyVRM from '../../../../components/misc/StickyVRM/StickyVRM';
import TrustpilotReviewsCarousel from '../../../../components/reviews/ReviewsCarousel/TrustpilotReviewsCarousel';
import { stepsConfig, stepsTitle } from '../../../../components/steps/config/howToConfig';
import StepsSection from '../../../../components/steps/StepsSection';
import BrandingContent from '../branding/BrandingContent';
import useBrandName from '../hooks/useBrandName';
import RecentSales from '../recentSales/RecentSales';
import TrustpilotBespoke from '../trustpilotBespoke/TrustpilotBespoke';
import VideoSection from '../videoSection/VideoSection';

import Hero from './Hero/Hero';
import USP from './Usp/Usp';

import styles from './Home.scss';

const DealerLogos = universal(() => import('../dealerLogos/DealerLogos'), {
  ignoreBabelRename: true,
  loadingTransition: false,
});

const Home = () => {
  const { configState: { brandData } } = useContext(ConfigContext);

  const [stickyVRM, setStickyVRM] = useState(false);
  const [failedReviewsCarousel, setFailedReviewsCarousel] = useState(false);

  const brandName = useBrandName();

  const $content = useRef(null);

  useEffect(() => {
    GA_TAGS.HOMEPAGE();
  }, []);

  useEffect(() => {
    if ($content.current) {
      setStickyVRM(true);
    }
  }, [$content]);

  const brand = brandData?.brand;

  return (
    <>
      <div className={styles.heroSection}>
        <Hero />
        <USP />
        <VideoSection />
      </div>
      <div ref={$content}>
        {
          stickyVRM && $content?.current && <StickyVRM $content={$content.current} />
        }
        <RecentSales />
        <div className={styles.stepsSectionWrapper}>
          <StepsSection
            withAnimation
            config={stepsConfig(brandName)}
            stepsClassName={styles.steps}
            title={stepsTitle}
          />
        </div>
        <div className={styles.hiddenOverflow}>
          {
            brand
              ? (<TrustpilotBespoke {...{ brand }} />)
              : (
                <Content className={styles.trustPilot} element="section" wrapper={{ className: cx(styles.trustPilotWrapper, { [styles.trustPilotWrapperEmpty]: failedReviewsCarousel }) }}>
                  <TrustpilotReviewsCarousel
                    largeTitle
                    failedLoad={failedReviewsCarousel}
                    setFailedLoad={setFailedReviewsCarousel}
                  />
                </Content>
              )
          }
          <DealerLogos />
          <BrandingContent />
        </div>
      </div>
    </>
  );
};

export default Home;
