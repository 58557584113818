import React from 'react';
import PropTypes from 'prop-types';

import T from 'i18n-react';

import { LoadingSpinner } from '@motorway/motorway-storybook-cra';

import { BUYER_SLUGS } from 'Utilities/buyers';

import Texts from '../../texts.json';
import { CarTakeBackLogo, TheCarBuyingGroupLogo } from '../app/fastlane/lazyLoadLogos/LazyLoadLogos';

import styles from './Checkout.scss';

T.setTexts(Texts);

const Checkout = ({
  buyer,
  failed = false,
}) => {
  const buyerSlug = Object.entries(BUYER_SLUGS).find(([k]) => (buyer?.slug === k))?.[1];

  let Icon;

  switch (buyerSlug) {
    case BUYER_SLUGS.ctb: {
      Icon = CarTakeBackLogo;
      break;
    }
    case BUYER_SLUGS.tcbg: {
      Icon = TheCarBuyingGroupLogo;
      break;
    }
    // no default
  }

  return (
    <div className={styles.component}>
      {
        (failed || !buyerSlug)
          ? (
            <div className={styles.section}>
              <h3>{T.translate('checkoutView.errorState.title')}</h3>
              <p>{T.translate('checkoutView.errorState.detail')}</p>
            </div>
          )
          : (
            <div className={styles.section}>
              <h3>{T.translate('checkoutView.title')}</h3>
              { Icon && (<Icon buyer={buyer.slug} className={styles.logoBuyer} />) }
              <LoadingSpinner size="large" />
              <p>{T.translate('checkoutView.detail', { buyer: <span className={styles.buyerName}>{buyer.name}</span> })}</p>
            </div>
          )
      }
    </div>
  );
};

Checkout.propTypes = {
  buyer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  failed: PropTypes.bool,
};

export default Checkout;
