/* eslint-disable max-len */
/* global Sentry */

/** React */
import React, { useContext } from 'react';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import universal from 'react-universal-component';
import PropTypes from 'prop-types';

import { ConfigContext } from 'Context/config';

import routing, {
  AFFILIATE_PAGE_ROUTES,
  GUIDE_ROUTES,
  HOME_COMPONENT_ROUTES,
  HOME_PAGE_ROUTES,
  SITEMAP_HTML_URL,
  STORY_ROUTES,
  VEHICLE_COLLECTION_ROUTE,
} from 'Utilities/routing';
import { HELP_URL, PRO_URL } from 'Utilities/urls';
import {
  LANDING_PAGE_ROUTES,
  MOT_PAGE_ROUTES,
  NEW_LANDING_PAGE_ROUTES,
  PART_EXCHANGE_PATH,
  SELL_YOUR_CAR_CITY_ROUTES,
  TAX_PAGE_ROUTES,
  ULEZ_PAGE_ROUTES,
  WORDPRESS_ROUTES,
} from 'Utilities/wordpress';

import PageLoader from '../../../components/transitions/PageLoader/PageLoader';
import PageLoaderError from '../../../components/transitions/PageLoaderError/PageLoaderError';
import { StoreContext } from '../../../store';
import { CUSTOMER_HUB_PATHS } from '../customerHub/CustomerHubHelpers';
import Status404 from '../error/404';
import ErrorBoundary from '../error/ErrorBoundary';
import Home from '../fastlane/home/Home';
import VehicleHeader from '../fastlane/vehicleHeaderWrapper/VehicleHeaderWrapper';
import Footer from '../footer/Footer';
import MetaTitle from '../metaTitle/MetaTitle';
import { SEO_PAGES_ROUTES } from '../SeoPages/helpers';
import ZendeskWidget from '../zendeskWidget/ZendeskWidget';

import styles from './Routes.module.scss';

const universalImportOptions = {
  error: <PageLoaderError />,
  ignoreBabelRename: true,
  loading: <PageLoader />,
  onError: (error, { isServer }) => {
    if (isServer === false && window.navigator.onLine && window.Sentry) {
      Sentry.withScope((scope) => {
        scope.setLevel('error');
        scope.setFingerprint(['Webpack Chunk Load Error']);
        Sentry.captureException(new Error(error));
      });
    }
    console.error(error); // eslint-disable-line no-console
  },
};

const vehicleHeaderLoader = { ...universalImportOptions, loading: (<VehicleHeader loading />) };
const signInPageOptions = { ...universalImportOptions, loading: (<PageLoader showFooter='full' />) };

/**
 * NOTE - It's important to use the universal imports to properly code split
 * For example if Home was a standard import, the buyer logo's it includes get's bundled into the vendor bundle
 * even if one logo is only used only in Home
 */
const VRM = universal(() => import(/* webpackPrefetch: true */ '../fastlane/vrm/VRM'), vehicleHeaderLoader);
const ValuationsRouter = universal(() => import(/* webpackPrefetch: true */ '../valuations/Pages/ValuationsRouter'), { ...vehicleHeaderLoader, key: (module) => module.ValuationsRouter });
const AcceptOffer = universal(() => import('../acceptOffer/AcceptOffer'), vehicleHeaderLoader);

const AccountPage = universal(() => import('../account/Pages/AccountPage/AccountPage'), universalImportOptions);
const SignIn = universal(() => import('../signIn/SignIn'), signInPageOptions);
const PasswordReset = universal(() => import('../authentication/Pages/PasswordResetPage/PasswordResetPage'), universalImportOptions);
const TokenExpired = universal(() => import('../fastlane/tokenExpired/TokenExpired'), universalImportOptions);
const UnsubscribePage = universal(() => import('../UnsubscribePage/UnsubscribePage'), universalImportOptions);
const LandingPages = universal(() => import('../contentPages/landingPages/LandingPages'), universalImportOptions);
const NewLandingPages = universal(() => import('../landingPages/Pages/LandingPage'), universalImportOptions);
const CheckerRouter = universal(() => import('../checkerPages/Pages/CheckerRouter'), universalImportOptions);
const UlezPage = universal(() => import('../contentPages/ulezPage/UlezPage'), universalImportOptions);
const BrandsContent = universal(() => import('../contentPages/brands/Brands'), universalImportOptions);
const HowItWorks = universal(() => import('../fastlane/howItWorks/HowItWorks'), universalImportOptions);
const ReviewsContent = universal(() => import('../contentPages/reviews/Reviews'), universalImportOptions);
const WordpressContent = universal(() => import('../contentPages/wordpress/Wordpress'), universalImportOptions);
const WordpressPages = universal(() => import('../contentPages/wordPressPages/WordpressPages'), universalImportOptions);
const AboutPage = universal(() => import('../contentPages/aboutUs/Page'), universalImportOptions);
const Payments = universal(() => import('../payments/Payments'), universalImportOptions);
const UnsupportedBrowser = universal(() => import('../../../components/misc/unsupportedBrowser/UnsupportedBrowser'), universalImportOptions);
const VehicleProfiling = universal(() => import('../vehicleProfiling/VehicleProfiling'), { ...universalImportOptions, key: (module) => module.VehicleProfiling });
const VehicleCollection = universal(() => import('../vehicleCollection/VehicleCollection'), { ...universalImportOptions, key: (module) => module.VehicleCollectionWithProviders });
const DocumentCapture = universal(() => import('../documentCapture/DocumentCapture'), universalImportOptions);
const AffiliatePage = universal(() => import('../affiliate/AffiliatePage/AffiliatePage'), universalImportOptions);
const Sitemap = universal(() => import('../sitemap/Sitemap'), universalImportOptions);
const CustomerHub = universal(() => import('../customerHub/Pages/CustomerHub'), universalImportOptions);
const SeoPage = universal(() => import('../SeoPages/SeoPage/SeoPage'), universalImportOptions);
const Refer = universal(() => import('../fastlane/refer/Refer'), universalImportOptions);
const VehicleDetailsPage = universal(() => import('../documentCapture/confirmSale/ConfirmationVehicleDetails/VehicleDetailsSection'), universalImportOptions);
const DocsEarly = universal(() => import('../documentCapture/uploadDocuments/DocsEarly/DocsEarly'), universalImportOptions);
const StandaloneBankDetailsPages = universal(() => import('../documentCapture/StandaloneBankDetails/StandaloneBankDetailsView'), universalImportOptions);
const ForgotPasswordPage = universal(() => import('../authentication/Pages/ForgotPasswordPage/ForgotPasswordPage'), universalImportOptions);
const Homepage = universal(() => import('../homepage/Pages/Homepage/Homepage'), universalImportOptions);
const Locations = universal(() => import('../locations/Locations'), universalImportOptions);

const nonStoreContextComponents = [
  [['/dealers', '/pro', '/guides/motorway-for-dealers'], () => {
    window.location.href = PRO_URL;
    return null;
  }],
  [['/faq', '/FAQ'], () => {
    window.location.href = HELP_URL;
    return null;
  }],
  [HOME_COMPONENT_ROUTES, Home],
  [HOME_PAGE_ROUTES, Homepage],
  ['/about', AboutPage],
  ['/how-it-works', HowItWorks],
  ['/refer', Refer],
  ['/reviews', ReviewsContent],
  [`${routing.vrmPath}/accept-offer`, AcceptOffer],
  [`${routing.vrmPath}/vehicle-payment`, Payments],
  ['/sign-in', SignIn],
  ['/token-expired', TokenExpired],
  ['/unsupported-browser', UnsupportedBrowser],
  ['/sell-my-car/brands', BrandsContent],
  ['/locations', Locations],
  [
    [
      `${routing.vrmPath}/confirm-sale-summary`,
      `${routing.vrmPath}/confirm-sale`,
      `${routing.vrmPath}/add-bank-details`,
      `${routing.vrmPath}/upload-documents/:kind?`,
      `${routing.vrmPath}/review-status`,
      `${routing.vrmPath}/purchasing-dealer-details`,
    ],
    DocumentCapture,
  ],
  [`${routing.vrmPath}${VEHICLE_COLLECTION_ROUTE}`, VehicleCollection],
  [`${routing.vrmPath}/vehicle-details`, VehicleDetailsPage],
  [`${routing.vrmPath}/profile/:step?/:step?/:step?`, VehicleProfiling],
  [AFFILIATE_PAGE_ROUTES, AffiliatePage],
  [ULEZ_PAGE_ROUTES, UlezPage],
  [SITEMAP_HTML_URL, Sitemap],
  [CUSTOMER_HUB_PATHS, CustomerHub],
  [SEO_PAGES_ROUTES, SeoPage],
  [
    [
      ...NEW_LANDING_PAGE_ROUTES,
      ...SELL_YOUR_CAR_CITY_ROUTES,
      ...[...NEW_LANDING_PAGE_ROUTES, ...SELL_YOUR_CAR_CITY_ROUTES].map((r) => `${r}/mileage`),
    ],
    NewLandingPages,
  ],
  ['/account', AccountPage],
  [`${routing.vrmPath}/get-ahead/upload-documents/:kind?`, DocsEarly],
  [
    [
      `${routing.vrmPath}/request/bank-details`,
      `${routing.vrmPath}/request/:kind?`,
      `${routing.vrmPath}/request/bank-details/confirmed`,
    ],
    StandaloneBankDetailsPages,
  ],
];

const useFeatureFlaggedRoutes = (featureFlags) => {
  const flaggedRoutes = [];

  if (featureFlags.showCognitoLogin) {
    flaggedRoutes.push(['/forgot-password/reset', PasswordReset], ['/forgot-password', ForgotPasswordPage]);
  }

  if (featureFlags.softAutoConfirmation) {
    flaggedRoutes.push([`${routing.vrmPath}/vehicle-details`, VehicleDetailsPage]);
  }

  if (featureFlags.showMotChecker) {
    flaggedRoutes.push([[...MOT_PAGE_ROUTES], CheckerRouter]);
  }

  if (featureFlags.showTaxChecker) {
    flaggedRoutes.push([[...TAX_PAGE_ROUTES], CheckerRouter]);
  }

  if (featureFlags.showPartExchangeLandingPage) {
    flaggedRoutes.push([[PART_EXCHANGE_PATH, `${PART_EXCHANGE_PATH}/mileage`], NewLandingPages]);
  }

  const VrmComponent = featureFlags.isNewValuationsRouter ? ValuationsRouter : VRM;
  flaggedRoutes.push([`${routing.vrmPath}`, VrmComponent]);

  if (featureFlags.showWordpressRefactoringUpdates) {
    flaggedRoutes.push([WORDPRESS_ROUTES, WordpressPages]);
  }

  return flaggedRoutes;
};

const useWordpressContentRoutes = () => {
  const base = [
    '/blog/:year?/:month?/:day?/:post?',
    '/claims',
    '/cookies',
    '/guides/:subdir?',
    '/press',
    '/privacy',
    '/terms',
  ];

  return [...base, ...[...GUIDE_ROUTES, ...STORY_ROUTES]];
};

const Routes = ({ hasError = false }) => {
  const { configState: { featureFlags } } = useContext(ConfigContext);

  const featureFlaggedRoutes = useFeatureFlaggedRoutes(featureFlags);
  const wordpressContentRoutes = useWordpressContentRoutes();

  const allRoutes = nonStoreContextComponents.concat(featureFlaggedRoutes);

  return (
    <>
      <MetaTitle />
      {!featureFlags.hideZendeskWidget && <ZendeskWidget />}
      <div className={styles.main} id="main">
        {/* Move as many components as possible out of `<StoreContext/>` */}
        <ErrorBoundary forceShow={hasError}>
          <Switch>
            {allRoutes.map(([path, component]) => <Route key={path} exact {...{ component, path }} />)}
            {
              <Route exact path='/unsubscribe/:sms?'>
                <UnsubscribePage />
              </Route>
            }
          </Switch>
        </ErrorBoundary>
        <StoreContext.Consumer>
          {(context) => (
            <ErrorBoundary forceShow={hasError}>
              <Switch>
                {/* Placeholder components to not trigger a 404 */}
                {allRoutes.map(([path]) => <Route key={path} exact render={() => (<></>)} {...{ path }} />)}
                <Route exact path='/unsubscribe/:sms?' render={() => (<></>)} />
                {/* End Placeholder components to not trigger a 404 */}

                { !featureFlags?.showWordpressRefactoringUpdates && <Route
                  exact
                  path={wordpressContentRoutes}
                  render={(props) => (<WordpressContent {...Object.assign(props, { context })} />)}
                /> }

                <Route
                  exact
                  path={[...LANDING_PAGE_ROUTES, ...LANDING_PAGE_ROUTES.map((r) => `${r}/mileage`)]}
                  render={(props) => (<LandingPages {...Object.assign(props, { context })} />)}
                />

                <Redirect exact from="/:vrm/:buyer/appointment/book" to={`${routing.vrmPath}`} />
                <Redirect exact from="/:vrm/:buyer/summary/:checkout?" to={`${routing.vrmPath}`} />
                <Redirect exact from={`${routing.vrmPath}/car-sold`} to={`${routing.vrmPath}`} />
                <Redirect exact from={`${routing.vrmPath}/sign-up`} to={`${routing.vrmPath}`} />

                <Route render={() => (<Status404 />)} />
              </Switch>
            </ErrorBoundary>
          )}
        </StoreContext.Consumer>
      </div>

      <Footer />
    </>
  );
};

Routes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hasError: PropTypes.any, // TODO - Create proper propType for hasError
};

export default Routes;
