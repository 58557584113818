import { useCallback, useContext, useEffect, useState } from 'react';
import { flushSync } from 'react-dom'; // eslint-disable-line no-restricted-imports

import { timeoutFetchSentryGuard } from 'Fetch';
import { getValuationStatus } from 'PrivateAPI';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { VALUATIONS_GA_TAGS } from 'Utilities/analytics';
import { HOMEPAGE_GA_TAGS } from 'Utilities/analytics/analyticsHomePage';
import { HERO_ANIMATION_STATES } from 'Utilities/animation';
import { stringNumberToInt } from 'Utilities/formatter';
import { checkHighMileage } from 'Utilities/formValidators';
import { getMileage } from 'Utilities/mileage';
import { isValuationRestrictedToModify } from 'Utilities/restricted';

import { HeroMileageProps } from '../../common/Hero/HeroInput/SubComponents/HeroInputMileageWrapper/HeroInputMileageWrapper';

export const useHeroMileageInputHelpers = ({
  isCarValueTracker, loadingHandler, onSubmit, setShowMileage,
}: Omit<HeroMileageProps, 'isHomePageRedesign' | 'showMileage'>) => {
  const { vehicleLookupActions, vehicleLookupState: lookupVehicle } = useContext(VehicleLookupContext);
  const [confirmedMileage, setConfirmedMileage] = useState<{mileage: string} | null>();
  const [enquiryStatus, setEnquiryStatus] = useState();
  const enquiryID = lookupVehicle?.enquiry?.dpEnquiryId;
  const isRestricted = isValuationRestrictedToModify(enquiryStatus);

  const vrm = lookupVehicle?.vrm;

  const updateMileage = useCallback(async ({ mileage }: { mileage: string }) => {
    const newMileage = stringNumberToInt(mileage);
    const currentMileage = getMileage({ vehicle: lookupVehicle });

    const updatePayload = {
      enquiry: {
        mileage: newMileage,
      },
    };

    // New mileage means new enquiry so nuke the enquiry ID to force a new enquiry
    // But if vehicle has restricted status, don't modify existing enquiry
    if (newMileage !== currentMileage && (!isRestricted)) {
      // @ts-expect-error
      updatePayload.enquiry.id = null;
    }

    // User is confirming their mileage by pressing continue,
    // So update enquiry so they don't have to fill it in at the next step
    vehicleLookupActions.update(updatePayload);

    if (!isCarValueTracker) {
      VALUATIONS_GA_TAGS.HERO_MILEAGE_SUBMITTED({ mileage, vrm });

      if (confirmedMileage) {
        VALUATIONS_GA_TAGS.CONFIRM_MILEAGE_MODAL_SUBMITTED({ mileage, vrm });
      }
    }

    loadingHandler(HERO_ANIMATION_STATES.mileage);
    onSubmit?.(newMileage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedMileage]);

  const onNotMyCarClick = useCallback(() => {
    VALUATIONS_GA_TAGS.HERO_MILEAGE_NOT_MY_CAR_CLICKED();

    if (!isCarValueTracker) {
      VALUATIONS_GA_TAGS.HERO_MILEAGE_NOT_MY_CAR_CLICKED_SNOWPLOW(vrm);
    }
    setShowMileage?.(false);
    vehicleLookupActions.remove();
  }, []);

  const onFormSubmit = useCallback(({ isHomePage, mileage }: { isHomePage?: boolean; mileage: string }) => {
    if (isHomePage) {
      HOMEPAGE_GA_TAGS.HERO_MILEAGE_CTA_CLICKED();
    }
    // @ts-ignore
    if (checkHighMileage(true, lookupVehicle)(mileage)) {
      setConfirmedMileage({ mileage });

      if (!isCarValueTracker) {
        VALUATIONS_GA_TAGS.CONFIRM_MILEAGE_MODAL({ mileage, vrm });
      }

      return;
    }
    flushSync(() => {
      updateMileage({ mileage });
    });
  }, [lookupVehicle, updateMileage, vrm]);

  const confirmMileageHelpers = useCallback(() => ({
    onConfirm: (value: { mileage: string }) => updateMileage(value),
    onReject: () => {
      setConfirmedMileage(null);

      if (!isCarValueTracker) {
        VALUATIONS_GA_TAGS.CONFIRM_MILEAGE_MODAL_CANCELLED(vrm);
      }
    },
  }), [updateMileage, vrm]);

  useEffect(() => {
    if (enquiryID) {
      getValuationStatus(enquiryID).then((data) => {
        setEnquiryStatus(data?.state?.slug);
      }).catch((err) => {
        timeoutFetchSentryGuard(err, () => window?.Sentry?.captureException(new Error(err)));
      });
    }
  }, [enquiryID]);

  return {
    confirmedMileage,
    confirmMileageHelpers,
    onFormSubmit,
    onNotMyCarClick,
  };
};
