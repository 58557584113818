import React from 'react';

import { Content } from '@motorway/motorway-storybook-cra';

import styles from './FooterSpacer.scss';

const FooterSpacer = () => (
  <Content className={styles.line} wrapper={{ className: styles.component }} />
);

export default FooterSpacer;
