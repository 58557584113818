import { gatewayGet, gatewayGetWithStatusCB, gatewayPost, gatewayPostWithStatusCB } from 'Fetch';

import { DPEnquiry } from 'Utilities/vehicles/@types';

import { PostMarkAsCollectedBody, PostSaleStatusResponse } from '../containers/app/vehicleCollection/types/apis.types';
import { PremiumOffer } from '../utilities/profiling/@types';

import { valuatePayload, valuateResponse } from './api.types';
import { platformPost } from './platformEndpoints';

// Platform top level endpoints (https://github.com/motorway/motorway-platform)
export const postValuate = async (payload: valuatePayload): valuateResponse => platformPost('/valuate', payload);

// EDA Vehicle Checker endpoints
export const getMOTVehicleCheck = async (vrm: string, env: 'prod' | 'stage', updateStatusCB?: (status?: number) => void, abortController?: AbortController) => gatewayGetWithStatusCB(`/vehicle-checker/${env}/mot/checker/${vrm}`, undefined, updateStatusCB, { abortController });

export const postMotNotifications = async (payload: { dpSellerId?: number; vrm?: string}, env: 'prod' | 'stage', updateStatusCB?: (status?: number) => void, abortController?: AbortController) => gatewayPostWithStatusCB(`/vehicle-checker/${env}/mot/notifications`, payload, undefined, updateStatusCB, { abortController });

// Dealership Platform endpoints (https://github.com/motorway/motorway-dealership-platform)
// Dealership Platform v3 endpoints
export const getEnquiryData = async (enquiryId: number, payload?: any, query = 'state,seller,vehicle,vehicleDetails'): Promise<DPEnquiry> => gatewayGet(`/premium-v3/enquiries/${enquiryId}`, { ...payload, ...(query.length && { include: query }) });
export const updateEnquiryData = async (enquiryId: number, payload: Partial<PremiumOffer>): Promise<DPEnquiry> => gatewayPost(`/premium-v3/enquiries/${enquiryId}/update`, { ...payload });
export const getSimpleEnquiryData = async (enquiryId: number, payload?: any, query = 'state,seller,vehicle,vehicleDetails') => gatewayGet(`/v3/enquiries/${enquiryId}`, { ...payload, ...(query.length && { include: query }) });
export const acceptOffer = async (payload: { closingBidId: number; enquiryId: number }, abortController?: AbortController) => gatewayPost('/premium-v3/bids/accept-offer', payload, undefined, { abortController });

// Transaction Core endpoints (https://api-docs.motorway.co.uk/mw-transaction-core)
export const getShortUrl = async (enquiryId: number, payload?: {field: string; path: string}, abortController?: AbortController): Promise<Record<string, string>> => gatewayPost(`/transaction/${enquiryId}/generateShortUrl`, payload, undefined, { abortController });

// PostSale endpoints (https://github.com/motorway/mw-post-sale-experience-api)
export const getPostSaleStatus = async (enquiryId: string, header: { Authorization?: string }, updateStatusCB?: (status?: number) => void, abortController?: AbortController): Promise<PostSaleStatusResponse> => gatewayGetWithStatusCB(`/post-sale/enquiries/${enquiryId}/sale-status`, {}, updateStatusCB, { abortController }, {
  ...header,
  'Content-Type': 'application/json',
});
export const postMarkAsCollected = async (saleId: string, body: PostMarkAsCollectedBody, header: { Authorization?: string }, updateStatusCB?: (status?: number) => void, abortController?: AbortController): Promise<PostSaleStatusResponse> => gatewayPostWithStatusCB(`/post-sale/sales/${saleId}/collected`, body, {
  ...header,
  'Content-Type': 'application/json',
}, updateStatusCB, { abortController });
