export const FLAG = {
  SELLER_UNDER_OFFER_FLOW: 'seller_under_offer_flow',
  SELLER_UNDER_OFFER_POC: 'seller_under_offer_poc',
  SKIP_EXPIRED_ENQUIRY: 'tc_skip_expired_enquiry',
  SWA_VEHICLE_HUB: 'swa_vehicle_hub',
} as const;

export const EVENT = {
  ACCEPT_OFFER_CTA_CLICKED: 'ps_accept_offer_cta_clicked',
  ACCEPT_OFFER_PAGE_LOADED: 'ps_accept_offer_page_loaded',
  CONFIRM_UNDER_OFFER_CTA_CLICKED: 'ps_confirm_under_offer_cta_clicked',
  ENQUIRY_CREATED: 'enquiry_created',
  OFFER_SELECT: 'offer_select',
  SELLER_UNDER_OFFER_POC: 'post_sale_poc',
  UNDER_OFFER_CONFIRM_SALE_PAGE_LOADED: 'ps_under_offer_confirm_sale_page_loaded',
  VEHICLE_HUB_VIEW_SECTIONS: 'vehicle_hub_view_sections',
} as const;

export const EXPERIMENT_DECISIONS_HEADER = 'experiment-decisions';
