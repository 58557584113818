import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';

import cypressIds from 'CypressId';

import { VehicleLookupContext } from 'Context/vehicleLookup';
import { VehicleContext } from 'Context/vehicles';

import { getCategoryForSticky, STICKY_VRM_EVENTS } from 'Utilities/analytics';
import { unprettyVRM } from 'Utilities/formValidators';
import { vehicleType } from 'Utilities/helpers';
import { applyCypressData } from 'Utilities/index';
import { classNamePropType, vehicleContextPropType, vehicleLookupContextPropType } from 'Utilities/propTypes';

import { VRMInput } from '../../../containers/app/fastlane/form/vrm/VRMInput';
import Texts from '../../../texts.json';

import styles from './VrmChecker.scss';

const T = new MDText(Texts);

const SELL_MY_CAR_URL = '/sell-my-car';
const formatVehicleLookupUrl = (value) => `/${value.replace(/ /g, '').toUpperCase()}`;

class VrmCheckerBase extends React.Component {
  constructor(props) {
    super(props);

    const { value } = this.props;

    this.state = {
      html5Validation: true,
      valueIsQuickVRM: !!value,
    };
  }

  componentDidMount() {
    this.setState({ html5Validation: false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      buttonProps, ...nextPropsValues
    } = nextProps;
    const {
      buttonProps: b, ...propsValues
    } = this.props;
    return !(JSON.stringify(nextState) === JSON.stringify(this.state)
      && JSON.stringify(nextPropsValues) === JSON.stringify(propsValues));
  }

  handleVrmSubmitToSellerPage = (value, reset) => {
    const {
      history,
      isWidget,
      location,
      snowplowEvents,
      vehicleContext,
      vehicleLookupContext,
    } = this.props;
    const { vehicleLookupActions } = vehicleLookupContext;
    const { vehicleState: vehicles } = vehicleContext;

    const sellMyCarPages = history.location.pathname.includes(SELL_MY_CAR_URL);
    const unprettyVrm = unprettyVRM(value);
    const existingVRM = vehicles.some((item) => item.vrm === unprettyVrm);

    const { pathname } = location;

    if (existingVRM) {
      const url = formatVehicleLookupUrl(value);

      if (snowplowEvents) {
        if (isWidget) {
          STICKY_VRM_EVENTS.STICKY_VRM_WIDGET_OLD_CTA_CLICK({
            category: getCategoryForSticky(pathname),
            label: value,
            url,
          });
        } else {
          STICKY_VRM_EVENTS.STICKY_VRM_BANNER_OLD_CTA_CLICK({
            category: getCategoryForSticky(pathname),
            label: value,
            url,
          });
        }
      }

      return history.push(url);
    }

    if (snowplowEvents) {
      if (isWidget) {
        STICKY_VRM_EVENTS.STICKY_VRM_WIDGET_OLD_CTA_CLICK({
          category: getCategoryForSticky(pathname),
          label: value,
          url: SELL_MY_CAR_URL,
        });
      } else {
        STICKY_VRM_EVENTS.STICKY_VRM_BANNER_OLD_CTA_CLICK({
          category: getCategoryForSticky(pathname),
          label: value,
          url: SELL_MY_CAR_URL,
        });
      }
    }

    vehicleLookupActions.add({ vehicle: { vrm: value } });

    if (sellMyCarPages) {
      reset();
    }

    return history.push(SELL_MY_CAR_URL);
  };

  handleVrmSubmit = (value) => {
    const {
      history,
      isWidget,
      location,
      snowplowEvents,
    } = this.props;

    const { pathname } = location;

    if (value) {
      const url = formatVehicleLookupUrl(value);

      if (snowplowEvents) {
        if (isWidget) {
          STICKY_VRM_EVENTS.STICKY_VRM_WIDGET_OLD_CTA_CLICK({
            category: getCategoryForSticky(pathname),
            label: value,
            url,
          });
        } else {
          STICKY_VRM_EVENTS.STICKY_VRM_BANNER_OLD_CTA_CLICK({
            category: getCategoryForSticky(pathname),
            label: value,
            url,
          });
        }
      }

      history.push(url);
    }
  };

  handleInputChange = (e) => {
    const { value } = this.props;
    this.setState({
      valueIsQuickVRM: e.target.value.toUpperCase() === value,
    });
  };

  handleChangeCarClick = (form) => (e) => {
    e.preventDefault();

    form.change('vrm', '');
    this.setState({
      valueIsQuickVRM: false,
    });
  };

  handleSubmit = ({ vrm }, form) => {
    const {
      analyticsEvents, directToSellerPage, handleVrmSubmit,
    } = this.props;

    const reset = () => {
      form.change('vrm', '');
    };

    analyticsEvents?.vrmSubmittedSuccessfully?.();
    if (handleVrmSubmit) {
      return handleVrmSubmit(vrm, reset);
    }
    if (directToSellerPage) {
      return this.handleVrmSubmitToSellerPage(vrm, reset);
    }
    return this.handleVrmSubmit(vrm, reset);
  };

  render() {
    const {
      analyticsEvents,
      buttonProps,
      chevron,
      className,
      disabled,
      e2eName,
      home,
      id,
      isWidget,
      large,
      location,
      required = true,
      snowplowEvents,
      value,
    } = this.props;

    const { valueIsQuickVRM } = this.state;

    let {
      className: buttonClassName,
      label,
      labelChildren,
      ...restButtonProps
    } = buttonProps;

    label = home ? T.translate('components.vrmChecker.buttonLabelValue') : T.translate('components.vrmChecker.buttonLabel', {
      vehicle: vehicleType(location.pathname),
    });
    label = (value && valueIsQuickVRM) ? T.translate('components.vrmChecker.loggedIn.buttonLabel') : label;
    label = labelChildren || buttonProps.label || label;

    const { pathname } = location;
    const isUlezSticky = getCategoryForSticky(pathname) === 'ulez';

    const onInputFocus = () => {
      if (snowplowEvents) {
        if (isWidget) {
          STICKY_VRM_EVENTS.STICKY_VRM_WIDGET_INPUT_FOCUS({
            category: getCategoryForSticky(pathname),
          });
        } else {
          STICKY_VRM_EVENTS.STICKY_VRM_BANNER_INPUT_FOCUS({
            category: getCategoryForSticky(pathname),
            label: isUlezSticky ? 'sticky' : 'old',
            ...(isUlezSticky && { name: 'vrm_input' }),
          });
        }
      } else {
        analyticsEvents?.onInputFocus?.();
      }
    };

    return (
      <Form
        validateOnBlur
        initialValues={{ vrm: value }}
        onSubmit={this.handleSubmit}
        render={({
          form, handleSubmit, validate,
        }) => (
        <form
          className={className}
          noValidate={!this.state.html5Validation}
          onSubmit={(event) => {
            form.setConfig('validate', validate);
            handleSubmit(event);
          }}
        >
          <fieldset disabled={disabled}>
            <VRMInput
              onChange={this.handleInputChange}
              onInputBlur={() => {
                analyticsEvents?.invalidVrmWarningDisplayed?.();
              }}
              onInputFocus={() => onInputFocus()}
              vrmId={id}
              {...{ e2eName, large, required }}
            />
            </fieldset>
            <div className={ cx(buttonClassName, {
              [styles.largeButton]: large,
            })}>
              <Button
              {...applyCypressData(cypressIds.buttons.vrmButton) }
                {...restButtonProps}
                fullWidth
                as="button"
                data-e2ename={`${e2eName} button`}
                disabled={disabled}
                icon={(home || chevron) ? 'chevron' : null}
                label={label}
                onClick={() => {
                  analyticsEvents?.onButtonClick?.();
                }}
                size={large ? 'large' : 'medium'}
                type="submit"
              />
            </div>
          {value && valueIsQuickVRM
            ? (
              <span>
                {T.translate('components.vrmChecker.loggedIn.changeCarLink', {
                  changeCarLink: <a href="#changecar" onClick={this.handleChangeCarClick(form)}>{T.translate('components.vrmChecker.loggedIn.changeCarLinkText')}</a>,
                })}
              </span>
            )
            : null}
        </form>
        )}
      />
    );
  }
}

VrmCheckerBase.defaultProps = {
  ...applyCypressData('vrmchecker2'),
  analyticsEvents: null,
  buttonProps: {},
  chevron: false,
  className: null,
  directToSellerPage: false,
  disabled: false,
  e2eName: '',
  handleVrmSubmit: null,
  home: false,
  id: '',
  isWidget: false,
  large: true,
  snowplowEvents: false,
  value: '',
  vehicleContext: null,
  vehicleLookupContext: null,
};

VrmCheckerBase.propTypes = {
  analyticsEvents: PropTypes.object,
  buttonProps: PropTypes.shape({
    className: classNamePropType,
    label: PropTypes.string,
    labelChildren: PropTypes.node,
  }),
  chevron: PropTypes.bool,
  className: classNamePropType,
  directToSellerPage: PropTypes.bool,
  disabled: PropTypes.bool,
  e2eName: PropTypes.string,
  handleVrmSubmit: PropTypes.func,
  history: ReactRouterPropTypes.history.isRequired,
  home: PropTypes.bool,
  id: PropTypes.string,
  isWidget: PropTypes.bool,
  large: PropTypes.bool,
  location: ReactRouterPropTypes.location.isRequired,
  snowplowEvents: PropTypes.bool,
  value: PropTypes.string,
  vehicleContext: vehicleContextPropType,
  vehicleLookupContext: vehicleLookupContextPropType,
};

// We have removed the original HOC connect approach.
// Even removing "connect" still passed context as props
// so using modern hooks, this is not an issue anymore
const VrmCheckerWrapper = React.forwardRef((props, ref) => {
  const history = useHistory();
  const location = useLocation();
  const vehicleContext = useContext(VehicleContext);
  const vehicleLookupContext = useContext(VehicleLookupContext);

  return (
    <VrmCheckerBase
      ref={ref}
      {...props}
      {...{
        history,
        location,
        vehicleContext,
        vehicleLookupContext,
      }}
    />
  );
});

export default VrmCheckerWrapper;
